<template>
  <view-item title="付款单详情">
    <template #manifest>
      <table-manifest
        name="付款单"
        code="PAMSEFD"
        :ajax="{ action: 'GET /enocloud/settlement/payment/query' }"
        :props="{ start: 'reservationStartDate', end: 'reservationEndDate' }"
        @expand-click="dialog.visible = true"
        @row-click="manifest.row.click"
      >
        <template #form="{ data }">
          <en-form-item label="付款搜索">
            <en-input v-model="data.quickSearch" placeholder="付款对象/付款单号/付款时间"></en-input>
          </en-form-item>
          <en-form-item label="付款时间">
            <en-date-picker v-model:start="data.startDate" v-model:end="data.endDate" type="daterange" value-format="YYYY-MM-DD"></en-date-picker>
          </en-form-item>
          <en-form-item label="业务单号">
            <en-input v-model="data.documentSerialNo"></en-input>
          </en-form-item>
          <en-form-item label="联系人">
            <select-maintain
              v-model="data.preparedById"
              :ajax="{
                action: 'GET /enocloud/common/user',
                params: (params, value) => {
                  params.payload = { name: value }
                }
              }"
              :props="{ label: 'name', value: 'id' }"
              value-key="id"
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="制单人">
            <select-maintain
              v-model="data.preparedById"
              :ajax="{
                action: 'GET /enocloud/common/user',
                params: (params, value) => {
                  params.payload = { name: value }
                }
              }"
              :props="{ label: 'name', value: 'id' }"
              value-key="id"
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="排序方式" prop="paymentSortedMethod">
            <select-maintain
              v-model="data.paymentSortedMethod"
              :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['PAMSTMTD']) }"
              :props="{ label: 'message', value: 'code' }"
              class="w-full"
            ></select-maintain>
          </en-form-item>
          <en-form-item label="备注">
            <en-input v-model="data.comment"></en-input>
          </en-form-item>
        </template>
      </table-manifest>
    </template>

    <view-item-content :disabled="!form.data.id">
      <en-card v-loading="form.loading" body-class="flex flex-col gap-6">
        <en-collapse>
          <en-collapse-item>
            <template #title>
              <div class="flex items-center gap-6">
                <span>{{ form.data.serialNo }}</span>
                <span>{{ form.data.branch?.shortName }}</span>
                <span>{{ form.data.preparedBy?.name }}</span>
                <span>{{ formatMoney(form.data.amount) }}</span>
                <span> {{ formatDate(form.data.businessDatetime) }}（单据）</span>
                <span> {{ formatDate(form.data.preparedDatetime) }}（付款）</span>
              </div>
            </template>
            <div class="grid grid-cols-4 gap-x-6">
              <span>备注：{{ form.data.comment }}</span>
            </div>
          </en-collapse-item>
        </en-collapse>
      </en-card>

      <en-card v-loading="form.loading" class="flex-1 overflow-auto" body-class="h-full">
        <flex-box>
          <en-table :data="form.data.paymentPayables">
            <en-table-column label="单据类型" prop="payable.type.message"></en-table-column>

            <en-table-column label="业务单号">
              <template #default="{ row }: { row: EnocloudSettlementDefinitions['PaymentDto'] }">
               
                <en-button type="primary" link @click="operation.open.click(row)" >{{ row.payable?.serialNo }} </en-button>
              </template>
            </en-table-column>

            <en-table-column label="应付款">
              <template #default="{ row }: { row: EnocloudSettlementDefinitions['PaymentDto'] }">
                {{ formatMoney(row.payable.amount) }}
              </template></en-table-column
            >
            <en-table-column label="已付款">
              <template #default="{ row }: { row: EnocloudSettlementDefinitions['PaymentDto'] }">
                {{ formatMoney(row.payable.paidAmount) }}
              </template>
            </en-table-column>
            <en-table-column label="付款">
              <template #default="{ row }: { row: EnocloudSettlementDefinitions['PaymentDto'] }">
                {{ formatMoney(row.amount) }}
              </template>
            </en-table-column>

            <en-table-column label="优惠减免">
              <template #default="{ row }: { row: EnocloudSettlementDefinitions['PaymentDto'] }">
                {{ formatMoney(row.badDebt) }}
              </template>
            </en-table-column>
            <en-table-column label="冲销金额">
              <template #default="{ row }: { row: EnocloudSettlementDefinitions['PaymentDto'] }">
                {{ formatMoney(row.paymentAdvanceDeposit) }}
              </template>
            </en-table-column>
          </en-table>
        </flex-box>
      </en-card>
    </view-item-content>
  </view-item>
  <order-dialog v-model="dialog.visible"></order-dialog>
</template>

<script lang="ts">
import OrderDialog from '@settlement/components/order-dialog.vue'
export default factory({
  components: { OrderDialog },
  config: {
    children: {
      operation: {
        open: {
          async  click(row:EnocloudSettlementDefinitions['PaymentDto']) {
            
            this.router.push('/accessory/stock/in/purchase', (vm) => {

              vm.form.data.id = row.payable.purchase.id

                    vm.form.get()
                  })
          }
        },
        add: {
          click() {
            this.form.init()
            this.detail.visible = true
          }
        },

        option: {
          async command(option: string) {
            switch (option) {
              case 'detail':
                this.detail.visible = true
                break
            }
          }
        }
      },
      manifest: {
        row: {
          click(row:EnocloudSettlementDefinitions['PaymentDto']) {
            this.form.init()
            this.form.data.id = row.id
            this.form.get()
          }
        }
      },
      tabs: {
        active: 'maintenance'
      },
      form: {
        data: {
          preparedDatetime: '',
          serialNo: '',
          preparedBy: {
            name: '',
            department: { name: '' }
          },
          auditBy: { name: '' },
          auditDatetime: '',
          payee: {
            name: '',
            accountInfo: {
              paymentAdvance: 0
            }
          },
          comment: '',
          paymentPayables: []
        },
        ajax: {
          get: {
            action: 'GET /enocloud/settlement/payment/:paymentId',
            data: 'object',
            loading: true,
            init: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          }
        },
        children: {
          maintenances: {
            selection: {
              data: [] as EnocloudSettlementDefinitions['PaymentDto'][],
              change(rows: EnocloudSettlementDefinitions['PaymentDto'][]) {
                this.form.maintenances.selection.data = rows
              }
            }
          }
        }
      },
      dialog: {
        visible: false
      },
      detail: {
        visible: false,
        confirm(id: number | undefined) {
          this.form.data.id ??= id
          this.form.get()
        }
      }
    }
  },
  mounted() {
    this.form.get()
  }
})
</script>
